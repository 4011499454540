import { publicPath } from "../config";
import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";
import { useState } from "react";

export default function WhyUs() {
  const [toggleState, setToggleState] = useState(true);
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <Navbar />
      <div className="why-us-sec-1">
        <div className="container">
          <h3>Boost up your Cloud with Us ! 🚀</h3>
          <p>
            Your engineers need to stay focused on building your core product.
            We are here to help you for your every
            <strong> Cloud</strong> and <strong>DevOps</strong> need
          </p>
          <hr />
          <a href="/#contact">Connect with Us!</a>
        </div>
      </div>

      <div className="why-us-sec-3" pos>
        <div className="upper-svg" style={{ position: "relative" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 65"
            style={{ position: "absolute", bottom: "0" }}
          >
            <path
              fill="#e2ffe9"
              fillOpacity="1"
              d="M1095.5 1.288c134 5.046 305.45 18.391 344.5 18.391v45.045H0V23.677C6.346 21.012 143.5 1.288 224.5 1.288c61.505 0 225 22.39 387 22.39 154.009 0 357-27.172 484-22.39z"
            />
          </svg>
        </div>
        <section className="why-us-container" style={{ position: "relative" }}>
          <div className="why-sec3-heading">
            <h2>Here are the outcomes you can expect from βetaque</h2>
          </div>
          <div className="container">
            <div className="faq">
            <div className="flap">
                <div className="flap-left flap-data">
                  <div
                    className={
                      toggleState === 1 ? "content-box active" : "content-box"
                    }
                    onClick={() => toggleTab(1)}
                  >
                    <div className="label">
                      Get your whole Infrastructure built from Scratch on any
                      Cloud
                    </div>
                    <div className="content1">
                      <p>
                        We aren't just restricted to AWS and GCP, Our Team can
                        get any Cloud setup for you.
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 2 ? "content-box active" : "content-box"
                    }
                    onClick={() => toggleTab(2)}
                  >
                    <div className="label">
                      Scale your existing Infrastructure, Make it Highly
                      available
                    </div>
                    <div className="content1">
                      <p>Bring your Infrastructure to 99.9% availability.</p>
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 3 ? "content-box active" : "content-box"
                    }
                    onClick={() => toggleTab(3)}
                  >
                    <div className="label">
                      Get 24x7 hours On-Call support for your Cloud and
                      Infrastructure
                    </div>
                    <div className="content1">
                      <p>
                        We do love sleep but What we love more is to care about
                        your Infrastructure.
                      </p>
                    </div>
                  </div>
                  <div
                    className={
                      toggleState === 4 ? "content-box active" : "content-box"
                    }
                    onClick={() => toggleTab(4)}
                  >
                    <div className="label">
                      Bring down your Cloud cost upto 80% with our DevOps
                      Experts
                    </div>
                    <div className="content1">
                      <p>
                        Worried about your Cloud Cost? We are here to Optimize
                        it.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="lower-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 65"
              style={{ position: "absolute", bottom: "0" }}
            >
              <path
                fill="white"
                fillOpacity="1"
                d="M1095.5 1.288c134 5.046 305.45 18.391 344.5 18.391v45.045H0V23.677C6.346 21.012 143.5 1.288 224.5 1.288c61.505 0 225 22.39 387 22.39 154.009 0 357-27.172 484-22.39z"
              />
            </svg>
          </div>
        </section>
        <div className="why-us-sec-2">
          <div className="container">
            <div className="info-container">
              <div className="row">
                <div className="col-6 perks-2-l">
                  <h2>What's our best part ?</h2>
                  <p>
                    <strong>Expand your team fast and easily:</strong>
                    Expand your team quickly without getting into process of
                    hiring, training and Managing it.
                  </p>
                  <p>
                    <strong>Certified Developers:</strong>
                    Get Cloud and Kubernetes Certified Developers.
                  </p>
                  <p>
                    <strong>Expertise in Domain:</strong>
                    We have expertise in our Domain which let us to implement
                    with Best practices.{" "}
                  </p>
                </div>
                <div className="col-6 info-2-r image">
                  <img
                    className="animated"
                    src={`${publicPath}/img/teamwork.jpg`}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
