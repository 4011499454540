import { publicPath } from "../config"
export function OurSpeciality() {
    return <section className="sec-2">
    <div className="container">
      <div className="heading">
        <h1><span>Our</span> Specialities</h1>
        <p>One Place Solution for Cloud and Infrastructure. HSD help companies to adopt and incorporate Cloud and DevOps with Ease.</p>
      </div>
      <div className="row">
        <div className="col-6">
          <div className="c-left">
            <div className="tab-1" id="tab-1" w-tab="tab-1" style= {{transition: "all 300ms ease 0s"}} >
              <div className="feature-img">
                <img src={publicPath+'/img/infra-monitoring-card.png'} alt=""/>
                <div className="feature-bg-color">
                </div>
              </div>
            </div>
            <div className="tab-2 d-none" id="tab-2" w-tab="tab-2">
              <div className="feature-img">
                <img src={publicPath+'/img/cloud-migration-index.png'} alt=""/>

                <div className="feature-bg-color green-light"></div>
              </div>
            </div>
            <div className="tab-3 d-none" id="tab-3" w-tab="tab-3">
              <div className="feature-img">
                <img src={publicPath+'/img/devops-index-card.png'} alt=""/>
                <div className="feature-bg-color blue-dark"></div>
              </div>
            </div>
            <div className="tab-4 d-none" id="tab-4" w-tab="tab-4">
              <div className="feature-img">
                <img src={publicPath+'/img/cloud-design-index.png'} alt=""/>
                <div className="feature-bg-color green-dark"></div>
              </div>
            </div>
            <div className="tab-5 d-none" id="tab-5" w-tab="tab-5">
              <div className="feature-img">
                <img src={publicPath+'/img/kubernetes-card.png'} alt=""/>
                <div className="feature-bg-color"></div>
              </div>
            </div>
            <div className="tab-6 d-none" id="tab-6" w-tab="tab-6">
              <div className="feature-img">
                <img src={publicPath+'/img/webdev-index-card.png'} alt=""/>
                <div className="feature-bg-color"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="c-right">
            <div className="carousel-out active tab-1" id="r-tab-1" w-tab="tab-1">
              <div className="carousel-topic" id="c">
                <div className="icon">
                  <i className="fas fa-chart-bar"></i>
                </div>
                <div className="solutions">
                  <a href="/services#serv-d">
                    <h4>Infrastructure Monitoring</h4>
                    <p>Monitor to Boost Reliability, Problem Detection & Troubleshooting, Better Alerts with HSD.</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-out tab-5" id="r-tab-5" w-tab="tab-5">
              <div className="carousel-topic" id="c">
                <div className="icon">
                  <i className="fab fa-google-plus"></i>
                </div>
                <div className="solutions">
                  <a href="/services#serv-c">
                    <h4>Kubernetes Migration</h4>
                    <p>Easily migrate Your Services to Kubernets Clusters.</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-out tab-4" id="r-tab-4" w-tab="tab-4">
              <div className="carousel-topic" id="c">
                <div className="icon">
                  <i className="fas fa-cogs"></i>
                </div>
                <div className="solutions">
                  <a href="/services#serv-f">
                    <h4>Cloud Architecture and Implementation</h4>
                    <p>We Architect highly Reliable, Available & scalable Infrastructure and implement it with best practices.</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-out tab-2" id="r-tab-2" w-tab="tab-2">
              <div className="carousel-topic" id="c">
                <div className="icon">
                  <i className="fas fa-cloud"></i>
                </div>
                <div className="solutions">
                  <a href="/services#serv-a">
                    <h4>Cloud Migration</h4>
                    <p>We help you to migrate to any Cloud Provider with minimum downtime.</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-out tab-3" id="r-tab-3" w-tab="tab-3">
              <div className="carousel-topic" id="c">
                <div className="icon">
                  <i className="fas fa-globe-americas"></i>
                </div>
                <div className="solutions">
                  <a href="/services#serv-e">
                    <h4>DevOps</h4>
                    <p>We Introduce and Maintain DevOps Culture in your company.</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-out tab-6" id="r-tab-6" w-tab="tab-6">
              <div className="carousel-topic" id="c">
                <div className="icon">
                  <i className="fas fa-desktop"></i>
                </div>
                <div className="solutions">
                  <a href="/services#serv-g">
                    <h4>Web Development</h4>
                    <p>Help you to Develop an Application that brings a change in the life of people.</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
