export function Footer() {
    return <footer className="footer">
    <div className="container-fluid">
      <div className="f-row">
        <div className="p1">
          <h1>
            <a href="/">HSD</a>
          </h1>
          <br />
        </div>
        <div className="nav-part">
          <div className="f-head"><a href="/services">Services</a></div>
          <div className="f-list f-service">
            <p><a href="/services#serv-d">Infrastructure Monitoring</a></p>
            <p><a href="/services#serv-c">Kubernetes Migration</a></p>
            <p><a href="/services#serv-b">Cloud Migration</a></p>
            <p><a href="/services#serv-a">Cloud Architecture and Implementation</a></p>
            <p><a href="/services#serv-e">DevOps Services</a></p>
            <p><a href="/services#serv-g">Web Development</a></p>
          </div>
        </div>
        <div className="nav-part">
          <div className="f-head"><a href="/">Company</a></div>
          <div className="f-list">
            <p><a href="/about">About</a></p>
            <p><a href="/careers">Careers</a></p>
            <p><a href="/whyus">Why Us</a></p>
          </div>
          
        </div>
        <div className="nav-part">
          <div className="f-head"><a href="/#contact">Contact</a></div>
          <div className="f-list">
            <a href="mailto:yash.raj@hsdsolution.com">yash.raj@hsdsolution.com</a>
            <p className="f-add">30 N Gould St STE ST R Sheridan, WY 82801, USA</p>
          </div>
        </div>
      </div>
      <div className="footer-text">Copyright © 2023 HSD Solutions Inc. All Rights Reserved</div>
      <div className="footer-block"><a href="/privacypolicy">Privacy & Policy</a></div>
    </div>
  </footer>
}
