import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";
import { publicPath } from "../config";

export default function Services() {
  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <>
      <Navbar />
      <div className="services-sec-1">
        <div className="container">
          <h3>
            <strong>OUR SERVICES</strong>
          </h3>
        </div>
      </div>
      <section className="services-sec2">
        <div className="container">
          <div className="service-blocks" id="serv-a">
            <div style={{ margin: "auto" }}>
              <div className="service_header">Cloud Migration Services</div>
              <p className="service_paragraph">
                HSD have vast experience in Cloud Migration and have proven
                framework and methodologies that includes multiple phases:
                strategy, planning, deployment, and administration phases that
                empower clients to adopt cloud platform, our services will help
                you with the hassle-free transformation from on-premise to cloud
                migration. It includes migration from on-premise to any cloud
                like AWS, Azure, and Google Cloud Platform or from one cloud
                provider to another like Heroku to AWS, etc.
              </p>
            </div>
            <div>
              <img
                src={`${publicPath}/img/cloud-migration.png`}
                alt=""
                className="service-img"
              />
            </div>
          </div>
          <div className="service-blocks" id="serv-b">
            <div>
              <img
                src={`${publicPath}/img/cloud-architecture.png`}
                alt=""
                className="service-img"
              />
            </div>
            <div style={{ margin: "auto" }}>
              <div className="service_header">
                Cloud Architecture and Design Implementation
              </div>
              <p className="service_paragraph">
                Design and scale Elastic Architectures in AWS, Azure, and
                Google, leveraging containerization. It’s never been easier to
                bring legacy applications to the cloud or build from scratch.
                HSD simplifies the whole process for you.
              </p>
            </div>
          </div>
          <div className="service-blocks" id="serv-c">
            <div style={{ margin: "auto" }}>
              <div className="service_header">
                Migrate to Kubernetes Services
              </div>
              <p className="service_paragraph">
                We provide a complete migration plan from any architecture to
                the Kubernetes cluster with our expertise and certified
                Kubernetes Administrators that help our client to manage, scale
                and deploy high available services in Kubernetes Clusters.
              </p>
            </div>
            <div>
              <img
                src={`${publicPath}/img/K8s.png`}
                alt=""
                className="service-img"
              />
            </div>
          </div>
          <div className="service-blocks" id="serv-d">
            <div>
              <img
                src={`${publicPath}/img/infra-logo.png`}
                alt=""
                className="service-img"
              />
            </div>
            <div style={{ margin: "auto" }}>
              <div className="service_header">
                Infrastructure Monitoring Services
              </div>
              <p className="service_paragraph">
                We provide a complete integration of Monitoring services that
                helps developers or infrastructure administrators to detect the
                pain points and debug seamlessly in the current infrastructure.
              </p>
            </div>
          </div>
          <div className="service-blocks" id="serv-e">
            <div style={{ margin: "auto" }}>
              <div className="service_header">DevOps</div>
              <p className="service_paragraph">
                HSD has advanced DevOps services on AWS, GCP, and Azure
                which includes end-to-end cloud solutions for roadmaps,
                continuous integration, continuous deployment, cost
                optimization, integration of other managed services, and
                maintaining infrastructure.
              </p>
            </div>
            <div>
              <img
                src={`${publicPath}/img/Devops.png`}
                alt=""
                className="service-img"
              />
            </div>
          </div>
          <div className="service-blocks" id="serv-g">
            <div>
              <img
                src={`${publicPath}/img/webdev-logo.png`}
                alt=""
                className="service-img"
              />
            </div>
            <div style={{ margin: "auto" }}>
              <div className="service_header">Web Development</div>
              <p className="service_paragraph">
                HSD not only works on Cloud and DevOps. We have a highly
                skilled and Qualified Engineer who has expertise in developing
                cutting-edge Software from Scratch. Languages and frameworks we
                work on are Nodejs/Typescript, Python, Django, etc.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
