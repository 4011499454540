
import Typed from "typed.js";
import { useEffect, useRef } from "react";
import { publicPath } from "../config"

export function Hero () {

  const el = useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Architect", "Implement", "Maintain"], 
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 500,
      smartBackspace: true,
      loop: true,
      showCursor: true,
    });

    // Destropying
    return () => {
      typed.destroy();
    };
  }, []);

    return <div className="section1">
    <div className="container">
      <div className="row">
        <div className="col-6 c-max">
          <div className="content">
          <div>
      <h3 style={{marginBottom: "20px"}}><span ref={el} className="typing"></span></h3>
    </div>
            <br/>
            <h2>Partners of Your Cloud Journey.</h2>
            <p className="p-title">
              You Focus on building core product. We handle your Infrastructure.
            </p>
            <a href="/services" id="map-btn" className="map">What We Offer</a>
          </div>
        </div>
        <div className="col-6">
          <div className="img">
            <img src={publicPath+'/img/codeYaan-hero.svg'} className="img-fluid animated" alt=""/>
            <div className="home-box-bottom"></div>
            <div className="home-box-top"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
}