import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";

export default function MERNDeveloper() {
  return (
    <>
      <Navbar />

      <header className="header">
        <div className="c-temp">
          <div className="container">
            <a href="/careers">
              <h3>Open Position</h3>
            </a>
            <hr />
            <p className="position-name">MERN Full Stack Developer(USA)</p>
            <div className="career-info">
              <div className="career-desc">
                <h4>Description</h4>
                <p>
                We are looking for a Full Stack Developer to produce scalable software solutions. As a Full Stack Developer, you should be comfortable with both front-end and back-end coding languages, development frameworks and third-party libraries. You should also be a team player with a knack for visual design and utility. It will be great if you are familiar with Agile methodologies.
                </p>
                
              </div>
              <div className="career-tech">
                <h4>Tech Stack:-</h4>
                <ul>
                  <li>MongoDB, Express,react,node</li>
                </ul>
              </div>
              <div className="career-reqmt">
                <h4>Requirements</h4>
                <ul>
                  <li>4+ years of working experience as a Full Stack Developer.</li>
                  <li>Extensive hands-on experience building services using these technologies (NodeJs, ExpressJs).</li>
                  <li>In-depth knowledge of web technologies like React JS/Next JS and Typescript.</li>
                  <li>In-depth knowledge and working experience on Restful/SOAP web services.</li>
                  <li>Experience with popular React.js workflows (Redux)</li>
                  <li>Proficiency with fundamental front-end languages such as HTML, CSS, and JavaScript.</li>
                  <li>Working knowledge of NoSQL/SQL and MongoDB database technologies.</li>
                  <li>Experience with AWS Cloud (specifically API Gateway) will be preferable.</li>
                  <li>Understanding of caching and third-party integration.</li>
                  <li>Familiar with code versioning tools (such as Git, SVN).</li>
                  <li>Knowledge of unit testing code using Jest & Enzyme.</li>
                  <li>Knowledge of Object or text recognition.</li>
                  <li>Excellent verbal communication skills.</li>
                  <li>BE/BTech. in Computer Science or other related fields of study or applicable work experience</li>
                </ul>
              </div>
              <div className="career-benefits">
                <h4>
                  <a href="/careers#careers-sec-2">
                    Know Your Perks <span>Here</span>
                  </a>
                </h4>
              </div>
            </div>
            <a href="mailto:yash.raj@hsdsolution.com" className="job-apply">
              Apply
            </a>
          </div>
        </div>
      </header>
      <Footer />
    </>
  );
}
