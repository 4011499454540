import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";

export default function DevopsCareer() {
  return (
    <>
      <Navbar />
      <header className="header">
        <div className="c-temp">
          <div className="container">
            <a href="/careers">
              <h3>Open Position</h3>
            </a>
            <hr />
            <p className="position-name">DevOps Engineer(USA)</p>
            <div className="career-info">
              <div className="career-desc">
                <h4>Description</h4>
                <p>
                  We are seeking a full-time DevOps engineer to join our
                  engineering team, who will be responsible for building,
                  designing, implementing and maintaining deployment automation
                  from code check-in to production.
                </p>
                <p>
                  The engineering team at HSD is agile and small, and
                  everyone gets an opportunity to have their hands-on multiple
                  products and technologies, while simultaneously finding areas
                  to focus on more closely over time.
                </p>
                <p>
                  This is a terrific opportunity to develop leading-edge
                  technology products and be at the forefront of innovation to
                  bridge the education information gap while getting exposure to
                  the EdTech landscape and contributing towards HSD's
                  mission ‘to make the world of work better’.
                </p>
                <p>
                  This position is ideal for someone who enjoys small
                  organizations, multi-tasking, and a highly flexible work
                  environment. Our engineers share a passion for making things
                  work in challenging environments and seeing the impact of our
                  work first-hand.
                </p>
              </div>
              <div className="career-tech">
                <h4>Tech Stack:-</h4>
                <ul>
                  <li>
                    AWS/GCP, Kubenetes(Managed Kubernetes services like GKE,
                    EKS)
                  </li>
                </ul>
              </div>
              <div className="career-reqmt">
                <h4>Requirements</h4>
                <ul>
                  <li>
                    Must have proficiency in Docker and Kubernetes and managed
                    service of Kubernetes like GKE/EKS.
                  </li>
                  <li>
                    He has worked on some monitoring tools/stacks like
                    Pormetheus-Grafana, ELK/EFK, Cloud watch or Datadog.
                  </li>
                  <li>
                    Have knowledge of Continuous Integration and Continuous
                    Deployments tools like Jenkins, CircleCI, github workflow.
                  </li>
                  <li>
                    Must have knowledge of any one scripting language
                    Python/Bash
                  </li>
                  <li>Some knowledge of any Cloud platform if, is a plus.</li>
                  <li>Knowledge of writing Kubernetes operator is plus</li>
                  <li>Knowledge of Helm or Kustomize is a plus.</li>
                </ul>
              </div>
              <div className="career-benefits">
                <h4>
                  <a href="/careers#careers-sec-2">
                    Know Your Perks <span>Here</span>
                  </a>
                </h4>
              </div>
            </div>
            <a href="mailto:yash.raj@hsdsolution.com" className="job-apply">
              Apply
            </a>
            {/* <p style="display: inline-block" className="job-apply">To Apply for this Job mail us at yash.raj@hsdsolution.com/p> */}
          </div>
        </div>
      </header>
      <Footer />
    </>
  );
}
