import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";

export default function MERNDeveloper() {
  return (
    <>
      <Navbar />

      <header className="header">
        <div className="c-temp">
          <div className="container">
            <a href="/careers">
              <h3>Open Position</h3>
            </a>
            <hr />
            <p className="position-name">Business Development Executive (USA)</p>
            <div className="career-info">
              <div className="career-desc">
                <h4>Description</h4>
                <p>
                We are looking for a resilient, empathic business development executive to contribute to the growth of our company. To be successful as a business development executive, you should attend networking events with the intention of attracting and retaining clientele. Ultimately, an outstanding business development executive will keep a close eye on clients' feedback to ensure that services always exceed expectations.
                </p>
                
              </div>
              <div className="career-tech">
                <h4>Tech Stack:-</h4>
                <ul>
                  <li>DevOps and Cloud / AWS </li>
                </ul>
              </div>
              <div className="career-reqmt">
                <h4>Requirements</h4>
                <ul>
                  <li>Must have 2 years of Experience.</li>
                  <li>Must have experience with different portals like Upwork, fiver, freelance.</li>
                  <li>Identifying new sales leads and lead generation(Via online Portals).</li>
                  <li>Proficiency in MS Office Word, Excel, PowerPoint, etc., as required.</li>
                  <li>Understanding the project requirement & Ability to develop good relationships with current and potential clients.</li>
                  <li>Email Etiquette, Proposal Writing, Follow-up and Requirement Gathering, Client Interaction.</li>
                  <li>High attention to detail and a focus on fact-based decision-making.</li>
                  <li>Contacting potential clients via email or phone to establish rapport and set up meetings.</li>
                  <li>Coordinate with management and clients to identify business development opportunities with   existing and new clients.</li>
                  <li>Work with the team to achieve short and long-term revenue and profit growth.</li>
                  <li>Write bids, proposals, brochures, and various other business informational letters.</li>
                  <li>Attend client conferences and meetings in order to network with new and existing contacts.</li>
                  <li>Excellent communication skills.</li>
                  <li>Bachelor's degree is required.</li>
                </ul>
              </div>
              <div className="career-benefits">
                <h4>
                  <a href="/careers#careers-sec-2">
                    Know Your Perks <span>Here</span>
                  </a>
                </h4>
              </div>
            </div>
            <a href="mailto:yash.raj@hsdsolution.com" className="job-apply">
              Apply
            </a>
          </div>
        </div>
      </header>
      <Footer />
    </>
  );
}
