import Typed from "typed.js";
import { useEffect, useRef, useState } from "react";
import { publicPath } from "../config";
import { Navbar } from "../Components/Navbar";
import { Footer } from "../Components/Footer";

export default function Careers() {
  const el = useRef(null);

  const [toggleState, setToggleState] = useState(true);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "Trust",
        "The Future",
        "Great Products",
        "An Incredible Company",
      ],
      startDelay: 300,
      typeSpeed: 100,
      backSpeed: 100,
      backDelay: 500,
      smartBackspace: true,
      loop: true,
      showCursor: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <>
      <Navbar />
      <header className="header">
        <div className="careers-sec-1">
          <div className="container">
            <h3>
              Let's Build <span ref={el} className="typing" /> Together
            </h3>
            <p>
              “Surround yourself with the dreamers and the doers, the believers
              and the thinkers, but most of all, surround yourself with those
              who see the greatness within you…”
            </p>
            <hr />
            <a href="#careers-sec-3">View Open Positions</a>
          </div>
        </div>
      </header>

      <div className="careers-sec-2" id="careers-sec-2">
        <div className="upper-svg" style={{ position: "relative" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{
              position: "absolute",
              bottom: "0",
              right: "0",
              left: "0",
            }}
            viewBox="0 0 1440 65"
          >
            <path
              fill="#e2ffe9"
              fillOpacity="1"
              d="M1095.5 1.288c134 5.046 305.45 18.391 344.5 18.391v45.045H0V23.677C6.346 21.012 143.5 1.288 224.5 1.288c61.505 0 225 22.39 387 22.39 154.009 0 357-27.172 484-22.39z"
            />
          </svg>
        </div>
        <div className="careers-container">
          <h4>
            <strong>Perks</strong> For Our People
          </h4>
          <p className="perks-quote">
            HSD is continuously adding benefits to enhance work-life balance
            and make your career & family more stable and secure. Join us to
            reshape the <strong>Cloud</strong> and <strong>Devops</strong>{" "}
            world.
          </p>

          <div className="flap">
            <div className="flap-left flap-data">
              <div
                className={
                  toggleState === 1 ? "content-box active" : "content-box"
                }
                onClick={() => toggleTab(1)}
              >
                <div className="label">
                  <p>Health</p>
                </div>
                <div className="content-c">
                  <p>Medical, dental, and vision benefits.</p>
                </div>
              </div>
              <div
                className={
                  toggleState === 2 ? "content-box active" : "content-box"
                }
                onClick={() => toggleTab(2)}
              >
                <div className="label">
                  <p>Pay</p>
                </div>
                <div className="content-c">
                  <p>Competitive salaries</p>
                </div>
              </div>
              <div
                className={
                  toggleState === 3 ? "content-box active" : "content-box"
                }
                onClick={() => toggleTab(3)}
              >
                <div className="label">
                  <p>Paid Vacation</p>
                </div>
                <div className="content-c">
                  <p>Paid time off including all standard holidays.</p>
                </div>
              </div>
              <div
                className={
                  toggleState === 4 ? "content-box active" : "content-box"
                }
                onClick={() => toggleTab(4)}
              >
                <div className="label">
                  <p>Company Events</p>
                </div>
                <div className="content-c">
                  <p>Exciting Cultural Events and Parties.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="lower-svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                left: 0,
              }}
              viewBox="0 0 1440 65"
            >
              <path
                fill="white"
                fillOpacity="1"
                d="M1095.5 1.288c134 5.046 305.45 18.391 344.5 18.391v45.045H0V23.677C6.346 21.012 143.5 1.288 224.5 1.288c61.505 0 225 22.39 387 22.39 154.009 0 357-27.172 484-22.39z"
              />
            </svg>
          </div>
        </div>

        <div className="container">
          <div className="perks-container">
            <div className="row">
              <div className="col-6 perks-1-l img">
                <img
                  className="animated"
                  src={`${publicPath}/img/careers-env.jpeg`}
                  alt=""
                />
              </div>
              <div className="col-6 perks-1-r">
                <h2>What does it mean to be at HSD?</h2>
                <p>
                  <strong>
                    We built HSD with like minded innovators and never have
                    a 'real job' again.
                  </strong>
                </p>
                <p>
                  Partners aren't employees, they are co-owners in our
                  profitable business. This is a uniquely awesome professional
                  home for you if you've got an entrepreneurial streak but you
                  don't want to start something on your own.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="careers-sec-3" id="careers-sec-3">
        <div className="container">
          <h3>Postions Open</h3>
          <p>
            We challenge ourselves each day to be better than we were yesterday,
            and we have a strong support system to help make it a reality.
          </p>
          <div className="openings">
            <div className="openings-name">
              <a href="./cloudengineer">
                Cloud Engineer(USA) <i className="fas fa-arrow-right" />
              </a>
              <a href="./devopsengineer">
                DevOps Engineer(USA) <i className="fas fa-arrow-right" />
              </a>
              <a href="./nodedeveloper">
                Node JS Developer(USA) <i className="fas fa-arrow-right" />
              </a>
              <a href="./merndeveloper">
                MERN Full Stack Developer(USA) <i className="fas fa-arrow-right" />
              </a>
              <a href="./businessdevelopment">
              Business Development Executive(USA) <i className="fas fa-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
