import { publicPath } from "../config"

export function BgColor() {
    return <><section className="bg-color">
        <div className="green-bg">
            <div className="container">
                <div className="partner-heading">
                    <h4><strong>Certified</strong> From</h4>
                </div>
                <div className="partner-logo">
                    <ul>
                        <li style={{
                            display: "flex",
                            justifyContent: "center"
                        }}><img src={publicPath+'/img/awslogo.svg'} alt="" style={{
                            height: "150px",
                            width: "120px",
                            paddingTop: "0px !important",
                            paddingBottom: "0px !important"
                        }} /></li>
                        <li><img src={publicPath+'/img/cloudguru-logo2.svg'} alt="" /></li>
                        <li><img src={publicPath+'/img/gcp-logo.svg'} alt="" /></li>
                        <li><img src={publicPath+'/img/redhatlogo.svg'} alt="" /></li>
                    </ul>
                </div>
            </div>
        </div>
    </section><svg xmlns="http://www.w3.org/2000/svg" className="index-g-svg" viewBox="0 0 1440 320"><path fill="#e2ffe9" fillOpacity="1" d="M0,192L34.3,176C68.6,160,137,128,206,112C274.3,96,343,96,411,133.3C480,171,549,245,617,234.7C685.7,224,754,128,823,112C891.4,96,960,160,1029,165.3C1097.1,171,1166,117,1234,106.7C1302.9,96,1371,128,1406,144L1440,160L1440,0L1405.7,0C1371.4,0,1303,0,1234,0C1165.7,0,1097,0,1029,0C960,0,891,0,823,0C754.3,0,686,0,617,0C548.6,0,480,0,411,0C342.9,0,274,0,206,0C137.1,0,69,0,34,0L0,0Z"></path></svg></>
}