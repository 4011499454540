import { Navbar } from "../Components/Navbar";
import { Hero } from "../Components/Hero";
import { Footer } from "../Components/Footer";
import { ContactUs } from "../Components/Contact";
import { OurSpeciality } from "../Components/OurSpeciality";
import { WhyChoose } from "../Components/WhyChoose";
import { BgColor } from "../Components/BgColor";

export default function Home() {
  return (
    <>
      <Navbar />
      <Hero />
      <OurSpeciality />
      <WhyChoose />
      <BgColor />
      <ContactUs />
      <Footer />
    </>
  );
}
