import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import WhyUs from "./pages/WhyUs";
import Careers from "./pages/Careers";
import DevopsCareer from "./pages/DevopsCareer";
import CloudCareer from "./pages/CloudCareer";
import NodeDeveloper from "./pages/NodeDeveloper";
import MERNDeveloper from "./pages/MERNDeveloper";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import BussinessDevelopment from './pages/BusinessDevelopment'

// eslint-disable-next-line no-undef
const routes = [
  {
    title: "Home",
    path: "/",
    Component: Home,
  },
  {
    title: "About",
    path: "/about",
    Component: About,
  },
  {
    title: "Services",
    path: "/services",
    Component: Services,
  },
  {
    title: "WhyUs",
    path: "/whyus",
    Component: WhyUs,
  },
  {
    title: "Careers",
    path: "/careers",
    Component: Careers,
  },
  {
    title: "DevOpsCareers",
    path: "/devopsengineer",
    Component: DevopsCareer,
  },
  {
    title: "CloudCareers",
    path: "/cloudengineer",
    Component: CloudCareer,
  },
  {
    title: "Node JS Developer",
    path: "/nodedeveloper",
    Component: NodeDeveloper,
  },
  {
    title: "MERN Full Stack Developer",
    path: "/merndeveloper",
    Component: MERNDeveloper,
  },
  {
    title: "BussinessDevelopment",
    path: "/businessdevelopment",
    Component: BussinessDevelopment,
  },
  {
    title: "PrivacyPolicy",
    path: "/privacypolicy",
    Component: PrivacyPolicy,
  },
];

export default routes;
